import React, {useState} from 'react';
import './styles.css';

const { REACT_APP_API_URL_IMAGES_PRODUCTOS_BP, REACT_APP_API_URL_IMAGES } = process.env;

const ProductCard = ({ product }) => {
    const [isZoomed, setIsZoomed] = useState(false);

    return (
        <div className="justify-center items-center flex">
            <div
                className={`card ${isZoomed ? 'zoomed' : ''} cursor-pointer border border-gray-200 rounded-lg shadow-md transition-transform duration-300 ease-in-out`}
                onClick={() => setIsZoomed(!isZoomed)}
            >
                <div className="p-4 flex gap-4">
                    <div className="flex gap-4">
                        <img
                            src={product.imagen ? `${REACT_APP_API_URL_IMAGES_PRODUCTOS_BP}/${product.imagen}` : `${REACT_APP_API_URL_IMAGES}public/producto_bp.jpg`}
                            alt={product.descripcion}
                            className="w-24 h-24 object-cover rounded"
                        />
                        <div>
                            <h3 className="font-bold text-lg">{product.descripcion}</h3>
                            <p className="text-gray-600">{product.marca}</p>
                            <p className="text-gray-600">{product.cod_barras}</p>
                            <div className="mt-2">
                                <p className="text-xl font-bold">${product.precio_pza}</p>
                                <div className="text-sm text-gray-500">
                                    <span>Min: ${(parseFloat(product.precio_pza ) - parseFloat(product.margen)).toFixed(2)}</span>
                                    <span className="mx-2">|</span>
                                    <span>Max: ${(parseFloat(product.precio_sug ) + parseFloat(product.margen)).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;