import axios from 'axios';
import { to } from 'await-to-js';
import { logoutAuth } from '../auth/index';

const { REACT_APP_API_URL } = process.env;

export async function buscarProducto(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/buscar-productos`, payload));

    if (error) {
        if (error.response.status === 403) {
            logoutAuth();
            window.setTimeout(function() {
                window.location.href = "/"
            }, 3000);
    
            return {
                status: false,
                mensaje: error.response.data.mensaje,
            };
        }

        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}
