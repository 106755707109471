import { Navigate, useRoutes } from 'react-router-dom';

// -------------------------------------- LOGIN ---------------------------------------
// ************************************************************************************
import Login from '../views/login';
import Logout from '../views/logout';

// --------------------------------------- HOME ---------------------------------------
// ************************************************************************************
import Layout from '../views/home/Layout';
import SimpleLayout from '../views/home/SimpleLayout';

import Home from '../views/home/Home';
import Opciones from '../views/opciones';
import MiCuenta from '../views/cuenta';
import Promociones from '../views/promociones';
import PesosLealtad from '../views/pesos-lealtad';
import CompraGana from '../views/compra-gana';
import CodigoBarras from '../views/codigo-barras';

// ------------------------------------ MICRO SITIO ------------------------------------
// *************************************************************************************
import MicroSitio from '../views/micro-sitio';
import { TicketProvider } from '../context/TicketContext';
import MasPromociones from '../views/micro-sitio/promociones';
import PesosLealtadMicroSitio from '../views/micro-sitio/pesos-lealtad';
import CompraGanaMicroSitio from '../views/micro-sitio/compra-gana';

// -------------------------------- BUSCADOR DE PRECIOS --------------------------------
// *************************************************************************************
import BuscadorPrecios from "../views/buscador-precios";

// import CustomComponents from "../views/custom-components/custom-components";
import { ClienteProvider } from '../context/ClienteContext';

import Page404 from '../views/Page404';

// AUTH
import { isLogin } from '../auth';

// ----------------------------------------------------------------------
// Usuarios
// (Tiendas, Usuario de consultas, Usuario con privilegios de alta de usuarios)
export default function Router() {
    const login = isLogin();

    const routes = useRoutes([
        {
            path: '/',
            element: !login ? <Login /> : <Navigate to="/app" />,
        },
        {
            path: '/app',
            element: login ? <ClienteProvider><Layout /></ClienteProvider> : <Navigate to="/" />,
            children: [
                { element: <Navigate to="/app/inicio" />, index: true },
                { path: 'inicio', element: <ClienteProvider><Home /></ClienteProvider> },
                { path: 'opciones', element: <ClienteProvider><Opciones /></ClienteProvider> },
                { path: 'mi-cuenta', element: <ClienteProvider><MiCuenta /></ClienteProvider> },
                { path: 'promociones', element: <ClienteProvider><Promociones /></ClienteProvider> },
                { path: 'pesos-lealtad', element: <ClienteProvider><PesosLealtad /></ClienteProvider> },
                { path: 'compra-gana', element: <ClienteProvider><CompraGana /></ClienteProvider> },
                { path: 'codigo-barras', element: <ClienteProvider><CodigoBarras /></ClienteProvider> },
                { path: 'salir', element: <Logout /> },
            ],
        },
        {
            element: <SimpleLayout />,
            children: [
                { path: 'micro-sitio', element: <TicketProvider><MicroSitio /></TicketProvider> },
                { path: 'micro-sitio/mas-promociones', element: <TicketProvider><MasPromociones /></TicketProvider> },
                { path: 'micro-sitio/pesos-lealtad', element: <TicketProvider><PesosLealtadMicroSitio /></TicketProvider> },
                { path: 'micro-sitio/compra-gana', element: <TicketProvider><CompraGanaMicroSitio /></TicketProvider> },
            ],
        },
        {
            element: <SimpleLayout />,
            children: [
                { path: 'buscador-precios', element: <BuscadorPrecios /> },
            ],
        },
        {
            element: <SimpleLayout />,
            children: [
                { element: <Navigate to="/app" /> },
                { path: '404', element: <Page404 /> },
                { path: '*', element: <Navigate to="/404" /> },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
